<template>
  <div class="leaderboard-page">
    <b-card>
      <b-row class="content-header d-flex justify-content-center">
        <b-col cols="12" md="12">
          <div class="row">
            <b-skeleton-wrapper :loading="isBusy" class="col-12">
              <template #loading>
                <div class="row">
                  <b-skeleton
                    type="input"
                    class="col-sm-12 col-md-12 col-lg-6 ml-1 mr-2"
                  ></b-skeleton>
                  <b-skeleton
                    type="input"
                    class="col-sm-12 col-md-6 col-lg-4 mr-2"
                  ></b-skeleton>
                  <b-skeleton type="button" class="catalog-btn"></b-skeleton>
                </div>
              </template>
              <b-input-group
                class="input-group-merge col-sm-12 col-md-12 col-lg-6"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block"
                  placeholder="Are you looking for any specific colleague? Search here"
                />
              </b-input-group>
              <v-select
                class="col-sm-12 col-md-6 col-lg-4 select-program"
                v-model="karma_program_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="karma_program_option"
                :reduce="(val) => val.hashid"
                @input="(val) => getLeaderboard()"
                label="label"
                :clearable="false"
                :searchable="true"
                input-id="karma_program_id"
                name="karma_program_id"
                placeholder="Select Karma Program"
              />
              <b-button
                variant="primary"
                class="ml-2 catalog-btn"
                :to="{
                  name: 'karma-catalogs',
                  params: { karma_program_id: karma_program_id },
                }"
              >
                <span class="font-weight-bold d-md-flex align-middle"
                  >Catalogs</span
                >
              </b-button>
            </b-skeleton-wrapper>
          </div>
        </b-col>
        <b-col cols="12" v-if="employeeListOptions.length > 0" class="mt-1">
          <b-list-group>
            <b-list-group-item
              v-for="(employee, index) in employeeListOptions"
              v-bind:key="index"
              class="user-points-list"
            >
              <b-link
                :to="{
                  name: 'karma-history',
                  params: {
                    id: employee.code,
                    karma_program_id: karma_program_id,
                  },
                }"
                class="font-weight-bold text-nowrap"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <span class="d-flex align-items-center">
                      <b-avatar
                        variant="info"
                        src=""
                        class="ml-2 mr-1 image"
                      ></b-avatar>
                      <span
                        class="
                          mr-auto
                          d-flex
                          flex-column
                          text-dark
                          font-weight-bold
                          name
                        "
                        >{{ employee.label }}</span
                      >
                    </span>
                  </div>
                </div>
              </b-link>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="leaderboard.length < 1" class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Data Not Available 🕵🏻‍♀️</h2>
        <p class="mb-2">
          Oops! 😖 May data is empty or Try adjusting your filter.
        </p>
        <b-img
          fluid
          :src="require('@/assets/images/pages/Search-rafiki.png')"
          alt="Error page"
          width="500"
        />
      </div>
    </div>
    <b-row class="mt-1" v-if="leaderboard.length > 0">
      <b-col
        sm="12"
        md="12"
        xl="5"
        class="d-flex justify-content-sm-start leader-board-left"
      >
        <div class="card col-12">
          <b-row class="self-board my-2">
            <b-col>
              <b-link
                :to="{
                  name: 'karma-history',
                  params: {
                    id: $cookies.get('userData').hashid,
                    karma_program_id: karma_program_id,
                  },
                }"
                class="font-weight-bold text-nowrap"
              >
                <div
                  class="
                    user-content
                    d-flex
                    bg-primary
                    justify-content-around
                    align-items-center
                    rounded
                    p-1
                  "
                >
                  <div class="rank text-center font-weight-bold">
                    <h3 class="text-white"><b>Rank</b></h3>
                    <h3 class="text-white">
                      <b>{{ myKarmaRank.ranked }}</b>
                    </h3>
                  </div>
                  <div class="user-image text-center">
                    <b-avatar
                      style="background-color: transparent"
                      title="Leaderboard Employee"
                      :src="$cookies.get('userData').avatar"
                      size="10rem"
                      class="mb-1 rounded-circle"
                    />
                    <h3 class="name text-white font-weight-bold">
                      <b>{{ $cookies.get("userData").name }}</b>
                    </h3>
                  </div>
                  <div class="points text-center font-weight-bold">
                    <h2 class="text-white"><b>Points</b></h2>
                    <h2 class="text-white">
                      <b>{{ myKarmaRank.highScore }}</b>
                    </h2>
                  </div>
                </div>
              </b-link>
            </b-col>
          </b-row>
          <b-row class="justify-content-center my-1"
            ><h2 class="leader-board-title">FRONT-RUNNERS</h2></b-row
          >
          <b-row class="my-1 py-2 d-flex justify-content-center top-winners">
            <b-col sm="12" md="4" v-if="topThree[1]">
              <div class="card border border-secondary second-place my-sm-3">
                <div class="leader-item second">
                  <b-link
                    :to="{
                      name: 'karma-history',
                      params: {
                        id: topThree[1].requested_for.hashid,
                        karma_program_id: karma_program_id,
                      },
                    }"
                    class="font-weight-bold text-nowrap text-center"
                  >
                    <div class="user-image">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Employee"
                        :src="topThree[1].requested_for.avatar"
                        size="5rem"
                        class="rounded-circle"
                      />
                    </div>
                    <div class="leader-trophy">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Winners"
                        :src="
                          require('@/assets/images/insider/silver-medal.png')
                        "
                        size="4rem"
                        class="rounded-circle"
                      />
                    </div>
                    <div class="user-content p-1">
                      <div class="name">
                        <h5 class="mb-0">
                          {{ topThree[1].requested_for.name }}
                        </h5>
                        <span
                          class="text-secondary small"
                          v-b-tooltip.hover.v-default
                          v-b-tooltip.hover.bottom
                          :title="topThree[1].requested_for.designation"
                        >
                          {{
                            topThree[1].requested_for.designation
                              | str_limit(15)
                          }}
                        </span>
                      </div>
                      <div class="points">
                        <span
                          >{{ topThree[1].highScore }}
                          <span class="small">Pts</span></span
                        >
                      </div>
                    </div>
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4" class="mobile-card" v-if="topThree[0]">
              <div class="card border border-secondary first-place my-sm-3">
                <div class="leader-item one">
                  <b-link
                    :to="{
                      name: 'karma-history',
                      params: {
                        id: topThree[0].requested_for.hashid,
                        karma_program_id: karma_program_id,
                      },
                    }"
                    class="font-weight-bold text-nowrap text-center"
                  >
                    <div class="user-image">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Employee"
                        :src="topThree[0].requested_for.avatar"
                        size="6rem"
                        class="rounded-circle"
                      />
                    </div>
                    <div class="leader-trophy">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Winners"
                        :src="require('@/assets/images/insider/gold-medal.png')"
                        size="6rem"
                      />
                    </div>
                    <div class="user-content p-1">
                      <div class="name">
                        <h5 class="mb-0">
                          {{ topThree[0].requested_for.name }}
                        </h5>
                        <span
                          class="text-secondary small"
                          v-b-tooltip.hover.v-default
                          v-b-tooltip.hover.bottom
                          :title="topThree[0].requested_for.designation"
                        >
                          {{
                            topThree[0].requested_for.designation
                              | str_limit(15)
                          }}
                        </span>
                      </div>
                      <div class="points">
                        <span
                          >{{ topThree[0].highScore }}
                          <span class="small">Pts</span></span
                        >
                      </div>
                    </div>
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="4" v-if="topThree[2]">
              <div class="card border border-secondary third-place my-sm-3">
                <div class="leader-item three">
                  <b-link
                    :to="{
                      name: 'karma-history',
                      params: { id: topThree[2].requested_for.hashid },
                    }"
                    class="font-weight-bold text-nowrap text-center"
                  >
                    <div class="user-image">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Employee"
                        :src="topThree[2].requested_for.avatar"
                        size="5rem"
                      />
                    </div>
                    <div class="leader-trophy">
                      <b-avatar
                        style="background-color: transparent"
                        title="Leaderboard Winners"
                        :src="
                          require('@/assets/images/insider/bronze-medal.png')
                        "
                        size="4rem"
                      />
                    </div>
                    <div class="user-content p-1">
                      <div class="name">
                        <h5 class="mb-0">
                          {{ topThree[2].requested_for.name }}
                        </h5>
                        <span
                          class="text-secondary small"
                          v-b-tooltip.hover.v-default
                          v-b-tooltip.hover.bottom
                          :title="topThree[2].requested_for.designation"
                        >
                          {{
                            topThree[2].requested_for.designation
                              | str_limit(15)
                          }}
                        </span>
                      </div>
                      <div class="points">
                        <span
                          >{{ topThree[2].highScore }}
                          <span class="small">Pts</span></span
                        >
                      </div>
                    </div>
                  </b-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        sm="12"
        md="12"
        xl="7"
        class="leader-board-right"
        v-if="leaderboard.length > 0"
      >
        <b-row align-h="between" class="p-1">
          <h2 class="leader-board-title">Leaderboard</h2>
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-row>
        <div class="leader-table">
          <b-list-group>
            <b-list-group-item
              v-for="(request, index) in leaderboard"
              v-bind:key="request.hashid"
              class="user-points-list"
            >
              <b-link
                :to="{
                  name: 'karma-history',
                  params: {
                    id: request.requested_for_id,
                    karma_program_id: karma_program_id,
                  },
                }"
                class="font-weight-bold text-nowrap"
              >
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    employee-view
                  "
                >
                  <div class="d-flex align-items-center">
                    <span class="count text-dark font-weight-bold count">
                      {{ meta.from + index }}
                    </span>
                    <span class="d-flex align-items-center info-view">
                      <b-avatar
                        variant="info"
                        :src="request.requested_for.avatar"
                        class="ml-2 mr-1 image"
                        rounded="circle"
                      ></b-avatar>
                      <span
                        class="
                          mr-auto
                          d-flex
                          flex-column
                          text-dark
                          font-weight-bold
                          name
                        "
                        v-b-tooltip.hover.v-default
                        v-b-tooltip.hover.bottom
                        :title="
                          request.requested_for.name +
                          ' (' +
                          request.requested_for.designation +
                          ')'
                        "
                        >{{ request.requested_for.name | str_limit(25) }}
                        <span class="text-secondary text-dark">
                          {{
                            request.requested_for.designation | str_limit(20)
                          }}</span
                        ></span
                      >
                    </span>
                  </div>
                  <b-badge variant="primary"
                    >{{ request.highScore }}
                    <span class="small">Pts</span></b-badge
                  >
                </div>
              </b-link>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import infiniteScroll from "vue-infinite-scroll";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
  BAvatar,
  BOverlay,
  BAlert,
  BSkeleton,
  BPagination,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "@/store";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import leaderboardStoreModule from "./leaderboardStoreModule";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BAlert,
    BSkeleton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    infiniteScroll,
  },
  data() {
    return {
      lastPage: 10,
      per_page: 10,
      total: 0,
      nextPage: 1,
      currentPage: 1,
      userData: this.$cookies.get("userData"),
      isBusy: false,
      data_local: {
        title: "",
        get_customfields_data: [],
      },
      searchQuery: "",
      leaderboard: [],
      employeeListOptions: [],
      topThree: [],
      designationOptions: [],
      myKarmaRank: [],
      filterByDesignationId: null,
      selected_program: "Program",
      program_option: [
        {
          label: "2023 Q1",
          code: "2WL5y",
        },
      ],
      karma_program_id: "",
      karma_program_option: [],
      meta: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-leaderboard-store";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, leaderboardStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getKarmaProgramOption();
    // this.getTopThree();
    // this.getLeaderboard();
    // this.getMyKarmaRank();
    this.fetchEmployeeList();
  },
  watch: {
    searchQuery(val) {
      if (val.length > 3 || val == "") {
        this.fetchEmployeeList();
      } else {
        this.employeeListOptions = [];
      }
    },
    currentPage(val) {
      this.getLeaderboard();
    },
    karma_program_id(val) {
      this.getTopThree();
      this.getMyKarmaRank();
    },
  },
  methods: {
    fetchEmployeeList() {
      if (!this.searchQuery) {
        this.employeeListOptions = [];
        return;
      }
      this.$store
        .dispatch("app/fetchEmployeeList", { q: this.searchQuery })
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    getKarmaProgramOption() {
      const self = this;

      let data = {};
      data.url = "list/karmaProgram";
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.karma_program_option = res.data.data;
          if (self.karma_program_option.length > 0 && !self.karma_program_id) {
            self.karma_program_id = self.karma_program_option[0].hashid;
            this.getLeaderboard();
            this.getTopThree();
            this.getMyKarmaRank();
          }

          this.isBusy = false;
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          this.isBusy = false;
        });
    },
    showPerson(person) {
      const self = this;
      self.userData = person;

      return true;
    },
    getLeaderboard() {
      const self = this;

      let data = {};
      data.url = "karma-leaderboard/";
      data.params = {
        q: self.searchQuery,
        page: self.currentPage,
        karma_program_id: self.karma_program_id,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.leaderboard = res.data.data;
          self.karma_program_option = res.data.karma_program_option;
          if (self.karma_program_option.length > 0 && !self.karma_program_id) {
            self.karma_program_id = self.karma_program_option[0].hashid;
          }
          this.nextPage = res.data.meta.current_page + 1;
          this.lastPage = res.data.meta.last_page;
          this.per_page = res.data.meta.per_page;
          this.currentPage = res.data.meta.current_page;
          this.total = res.data.meta.total;
          this.meta = res.data.meta;
          if (res.data.data.length > 0) {
            self.userData = res.data.data[0];
          }
          this.isBusy = false;
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          this.isBusy = false;
        });
    },
    getTopThree() {
      const self = this;

      let data = {};
      data.url = "karma-leaderboard/";
      data.params = {
        perPage: 3,
        karma_program_id: self.karma_program_id,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.topThree = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          this.isBusy = false;
        });
    },
    getMyKarmaRank() {
      const self = this;

      let data = {};
      data.url = "get-user-karma-rank/";
      data.params = {
        karma_program_id: self.karma_program_id,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.myKarmaRank = res.data.data;
          this.isBusy = false;
        })
        .catch((error) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          this.isBusy = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
